import React, { useCallback, useState } from 'react';
import Loadable from 'react-loadable';
import {
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
} from '../../../../components';
import dividerPreviewData from '../../../../data/previews/divider.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Divider = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Divider;
    return <Component {...props} />;
  },
});

const Input = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Input;
    return <Component {...props} />;
  },
});

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  const [dividerType, setDividerType] = useState('default');

  const handleChange = useCallback(value => {
    setDividerType(value);
  }, []);

  return (
    <PageWithSubNav
      pageType="design"
      title="Divider"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Themes" tierThree="Divider" />

      <PageNavigation
        links={['Width', 'Orientation', 'Type', 'Usage', 'Platform']}
      />

      <Section title="Width">
        <Paragraph>
          Dividers are important, yet subtle. It’s critical to choose the
          correct width when separating content.
        </Paragraph>
        <ComponentPreview
          name="DividerWidth"
          layout="dividerPreview"
          previewData={dividerPreviewData.widths}>
          <Divider orientation="horizontal" color="divider" />
        </ComponentPreview>
      </Section>

      <Section title="Orientation">
        <Paragraph>
          Separating content can be done within a single or multi-column layout.
          Choosing the correct orientation provides coherence of the purpose of
          the interface.
        </Paragraph>
        <ComponentPreview
          name="DividerOrientation"
          layout="dividerPreview"
          previewData={dividerPreviewData.orientations}>
          <Divider width="two" color="divider" />
        </ComponentPreview>
      </Section>

      <Section title="Type">
        <Paragraph>
          Dividers can provide visual enhancements for particular elements or as
          an explicit division between items in lists or related content within
          a section.
        </Paragraph>
        <ComponentPreview
          name="DividerType"
          layout="default"
          previewData={dividerPreviewData.types}
          onChange={handleChange}>
          {dividerType === 'default' && (
            <div>
              <Input size="medium" label="First name" />
              <Input size="medium" label="Last name" />
              <Divider
                orientation="horizontal"
                width="one"
                color="divider"
                className="uni-margin--one-and-half--vertical"
              />
              <Input size="medium" label="Address" />
              <Input size="medium" label="City" />
            </div>
          )}
          {dividerType === 'inset' && (
            <div>
              <Text level="large">Michael Jordan</Text>
              <Divider
                width="one"
                orientation="horizontal"
                color="divider"
                className="uni-margin--three-quarter"
              />
              <Text level="large">Larry Bird</Text>
              <Divider
                width="one"
                orientation="horizontal"
                color="divider"
                className="uni-margin--three-quarter"
              />
              <Text level="large">Magic Johnson</Text>
            </div>
          )}
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <Paragraph>
          Dividers can be applied to any part of an interface, but should be
          relational to the level they’re placed on. Make use of{' '}
          <Link href="/guidelines/layout/division/design" isDesignCode>
            division
          </Link>{' '}
          to group information relationally.
        </Paragraph>
        <DontDo
          dontText="mix and match level and level accents."
          doText="choose the correct divider for the segment."
          imgFilename="divider-mix-levels"
        />

        <DontDo
          dontText="divide content unreasonably."
          doText="provide visual pause when required."
          imgFilename="divider-unreasonable"
        />

        <DontDo
          dontText="combine divider widths within the same element."
          doText="provide visual pause when necessary."
          imgFilename="divider-widths"
        />
      </Section>

      <Section title="Platform">
        <PlatformTable />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
